import { Injectable } from '@angular/core';
Injectable()
export class Globals {

    firebaseToken = "";
    ids :Set<number>

    public static pendingOrderCount = 0;
    public static ordersToDeliveredCount = 0;
    public static outToDeliveryCount = 0;
    public static deliveryOrderCount = 0 
    public static cancelledOrdersCount = 0 
    getIds(){
        this.ids = new Set<number>();
    }

}



