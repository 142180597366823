import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';

import { AdminRegistrationModel } from '../../_models/admin-registration.model';
import { AdminAuthModel } from '../../_models/admin-auth.model';
import { ResponseModel } from 'src/app/modules/common/_models/response.model';

// const API_USERS_URL = `${environment.apiUrl}/auth`;
const API_USERS_URL = `${environment.apiUrl}`;  // development

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) { }

  // public methods
  login(email: string, password: string, role: string): Observable<any> {
    return this.http.post<ResponseModel>(API_USERS_URL + '/auth/login', { email, password, role });
  }

  logout(httpHeaders): Observable<any> {
    return this.http.post<any>(API_USERS_URL + '/auth/logout' ," ", { headers: httpHeaders });
  }


  // CREATE =>  POST: add a new user to the server
  createUser(user: AdminRegistrationModel): Observable<AdminRegistrationModel> {
    return this.http.post<AdminRegistrationModel>(API_USERS_URL + '/register/admin', user);
  }
  getUser(userId): Observable<AdminAuthModel> {
    const httpHeaders = this.getHTTPHeaders();
    let urlpath = `${environment.apiUrl}`;
    return this.http.get<AdminAuthModel>(urlpath + '/admin/registration/' + userId, { headers: httpHeaders });
  }
  changePassword(requestBody: any): Observable<boolean> {
    const httpHeaders = this.getHTTPHeaders();
    let urlpath = `${environment.apiUrl}`;
    return this.http.post<boolean>(urlpath + '/admin/registration/change-password', requestBody, { headers: httpHeaders });

  }
  
  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(requestBody: any): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/auth/send-otp`, requestBody);
  }

  setNewPassword(requestBody: any): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/auth/reset-password`, requestBody);
  }
  resendOtp(requestBody: any): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/auth/send-otp`, requestBody);
  }

  checkOtp(requestBody: any): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/auth/match-otp`, requestBody);
  }

  getUserByToken(token): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<UserModel>(`${API_USERS_URL}/me`, {
      headers: httpHeaders,
    });
  }

  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  public getHTTPHeaders(): HttpHeaders {
    let result = new HttpHeaders();
    const accessToken = localStorage.getItem(this.authLocalStorageToken);
    // result = result.set('Content-Type', 'application/json');
    result = result.set('Authorization', accessToken);
    // result = result.set('sessionid', sessionId);
    return result;
  }

}
