
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthModel } from '../_models/auth.model';
import { AuthHTTPService, AuthFakeHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AdminRegistrationModel } from '../_models/admin-registration.model';
import { ResponseModel } from '../../common/_models/response.model';
import { AdminAuthModel } from '../_models/admin-auth.model';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  subscriptions: Subscription[] = [];

  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<AdminAuthModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<AdminAuthModel>;
  isLoadingSubject: BehaviorSubject<boolean>;


  get currentUserValue(): AdminAuthModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: AdminAuthModel) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private authFakeHttpService: AuthFakeHTTPService,

    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<AdminAuthModel>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    // const subscr = this.getUserByToken().subscribe();
    // this.unsubscribe.push(subscr);
  }

  // public methods
  login(email: string, password: string): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authFakeHttpService.login(email, password).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        return auth;
      }),
      // switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  loginOnServer(email: string, password: string, role: string): Observable<ResponseModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password, role).pipe(
      map((res: ResponseModel) => {
        const data = {
          id: res.id,
          name: res.name,
          accessToken: res.token,
          teamPermissions: res.teamPermissions,
          email: res.email,
          clear(){
          }
        }
        // localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth.accessToken));
        // res.data.email=email;
        const result = this.setAuthFromLocalStorage(data);
        this.currentUserSubject = new BehaviorSubject<AdminAuthModel>(data);
        return res;
      }),
      // switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem(this.authLocalStorageToken)}`,
    });
    const logOut = this.authHttpService
      .logout(httpHeaders)
      .pipe()
      .subscribe((result: any) => {
      //  console.log(result)
      });
    this.unsubscribe.push(logOut);
    localStorage.removeItem(this.authLocalStorageToken);
    localStorage.removeItem('user');
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<AdminAuthModel> {
    const auth = this.getAuthFromLocalStorage();
   
    if (!auth ) {
      return of(undefined);
    }
    this.isLoadingSubject.next(true);
    const user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : '';
    if (user) {
      this.currentUserSubject = new BehaviorSubject<AdminAuthModel>(user);
      this.setAuthFromLocalStorage(user)
      // return user;
    } else {
      this.logout();
    }

    finalize(() => this.isLoadingSubject.next(false))

    // this.isLoadingSubject.next(true);
    // return this.authFakeHttpService.getUserByToken(auth.authToken).pipe(
    //   map((user: UserModel) => {
    //     if (user) {
    //       this.currentUserSubject = new BehaviorSubject<UserModel>(user);
    //     } else {
    //       this.logout();
    //     }
    //     return user;
    //   }),
    //   finalize(() => this.isLoadingSubject.next(false))
    // );
  }

  // need create new user then login
  registration(user: AdminRegistrationModel): Observable<any> {
    this.isLoadingSubject.next(true);
    // let user1 = new UserModel();
    // return this.authHttpService.createUser(user1).pipe(
    //   map(() => {
    //     this.isLoadingSubject.next(false);
    //     return user;
    //   }),
    //   // switchMap(() => this.login(user.email, user.password)),
    //   catchError((err) => {
    //     console.error('err', err);
    //     return of(undefined);
    //   }),
    //   finalize(() => this.isLoadingSubject.next(false))
    // );


    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
        return user;
      }),
      // switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }


  getUserById(userId): Observable<AdminAuthModel> {
    // const auth = this.getAuthFromLocalStorage();

    // if (!auth) {
    //   return of(undefined);
    // }
    this.isLoadingSubject.next(true);
    return this.authHttpService.getUser(userId);
      // .pipe(finalize(() => this.isLoadingSubject.next(false)));

    // this.isLoadingSubject.next(true);
    // const user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : '';
    // if (user) {
    //   this.currentUserSubject = new BehaviorSubject<AdminAuthModel>(user);
    //   this.setAuthFromLocalStorage(user)
    //   return user;
    // } else {
    //   this.logout();
    // }
  }

  changePassword(requestBody): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .changePassword(requestBody)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  forgotPassword(requestBody): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(requestBody)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  setNewPassword(requestBody): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .setNewPassword(requestBody)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  resendOtp(requestBody): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .resendOtp(requestBody)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  checkOtp(requestBody): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .checkOtp(requestBody)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }





  // private methods
  public setAuthFromLocalStorage(auth: any): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.accessToken) {

      localStorage.setItem(this.authLocalStorageToken, (auth.accessToken));
      localStorage.setItem("user", JSON.stringify(auth));
      console.log(localStorage.getItem(this.authLocalStorageToken))

      this.currentUserSubject = new BehaviorSubject<AdminAuthModel>(auth);
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): AdminAuthModel {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }












}
