import { HttpHeaders, HttpParams } from '@angular/common/http';
// tslint:disable:variable-name
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { PaginatorState } from '../models/paginator.model';
import { ITableState, TableResponseModel } from '../models/table.model';
import { BaseModel } from '../models/base.model';
import { SortState } from '../models/sort.model';
import { GroupingState } from '../models/grouping.model';
import { environment } from '../../../../../environments/environment';
import { Globals } from 'src/app/modules/common/_services/globals.service';
import { RestaurantMenuModel } from 'src/app/modules/dispatcher/_models/restaurant-order.model';


const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  grouping: new GroupingState(new Globals),
  entityId: undefined
};

export abstract class TableService<T> {
  // Private fields
  private _items$ = new BehaviorSubject<T[]>([]);
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  private _isFirstLoading$ = new BehaviorSubject<boolean>(true);
  private _tableState$ = new BehaviorSubject<ITableState>(DEFAULT_STATE);
  private _errorMessage = new BehaviorSubject<string>('');
  private _subscriptions: Subscription[] = [];
  
  globals = new Globals();
  // Getters
  get items$() {
    return this._items$.asObservable();
  }
  get isLoading$() {
    return this._isLoading$.asObservable();
  }
  get isFirstLoading$() {
    return this._isFirstLoading$.asObservable();
  }
  get errorMessage$() {
    return this._errorMessage.asObservable();
  }
  get subscriptions() {
    return this._subscriptions;
  }
  // State getters
  get paginator() {
    return this._tableState$.value.paginator;
  }
  get filter() {
    return this._tableState$.value.filter;
  }
  get sorting() {
    return this._tableState$.value.sorting;
  }
  get searchTerm() {
    return this._tableState$.value.searchTerm;
  }
  get grouping() {
    return this._tableState$.value.grouping;
  }
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  public getHTTPHeaders(): HttpHeaders {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem(this.authLocalStorageToken)}`,
      "Content-Type":"application/json"
    });
    // let result = new HttpHeaders();
    // const accessToken = localStorage.getItem(this.authLocalStorageToken);
    // // result = result.set('Content-Type', 'application/json');
    // result = result.set('Authorization', accessToken);
    // result = result.set('sessionid', sessionId);
    return httpHeaders;
  }

  protected http: HttpClient;

  // API URL has to be overrided
  API_URL = `${environment.apiUrl}`;
  constructor(http: HttpClient) {
    this.http = http;

    this.globals.getIds()
  }

  // CREATE
  // server should return the object with ID
  create(item: BaseModel): Observable<BaseModel> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const httpHeaders = this.getHTTPHeaders();
    return this.http.post<BaseModel>(this.API_URL, item, { headers: httpHeaders })
    // .pipe(
    //   catchError(err => {
    //     this._errorMessage.next(err);
    //     console.error('CREATE ITEM', err);
    //     return of({ id: undefined });
    //   }),
    //   finalize(() => this._isLoading$.next(false))
    // );
  }
  createWithUrl(item: BaseModel,url): Observable<BaseModel> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const httpHeaders = this.getHTTPHeaders();
    let URL = `${environment.apiUrl}${url}`;
    return this.http.post<BaseModel>(URL, item, { headers: httpHeaders })
    // .pipe(
    //   catchError(err => {
    //     this._errorMessage.next(err);
    //     console.error('CREATE ITEM', err);
    //     return of({ id: undefined });
    //   }),
    //   finalize(() => this._isLoading$.next(false))
    // );
  }
  createWithImageUpload(request): Observable<BaseModel> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const httpHeaders = this.getHTTPHeaders();
    return this.http.post<BaseModel>(this.API_URL, request, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('CREATE ITEM', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getOrderCount() {
    const httpHeaders = this.getHTTPHeaders();
    return this.http.get<RestaurantMenuModel[]>(`${environment.apiUrl}/order/count`,{ headers: httpHeaders })
  }
  changeOrderStatus(body) {
    const httpHeaders = this.getHTTPHeaders();
    var url=`/order/restaurant/update-order-status?placedOrderId=${body.placedOrderId}&orderStatus=${body.orderStatus}&restaurantId=${body.restaurantId}`;
    return this.http.get<any>(`${environment.apiUrl}${url}`, { headers: httpHeaders })
  }

  // createWithImageInBodyAndParam(bodyPart,httpParam){
  //   this._isLoading$.next(true);
  //   this._errorMessage.next('');
  //   const httpHeaders = this.getHTTPHeaders();
  //   return this.http.post<BaseModel>(this.API_URL, bodyPart, { headers: httpHeaders,params:httpParam }).pipe(
  //     catchError(err => {
  //       this._errorMessage.next(err);
  //       console.error('CREATE ITEM', err);
  //       return of({ id: undefined });
  //     }),
  //     finalize(() => this._isLoading$.next(false))
  //   );

  // }
  updateWithImageUpload(request, id): Observable<BaseModel> {
  
    
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const httpHeaders = this.getHTTPHeaders();
    return this.http.put<BaseModel>(this.API_URL + "/" + id, request, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('CREATE ITEM', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  createWithOutHeader(request): Observable<BaseModel> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    let URL = `${environment.apiUrl}/restaurant/register`;
    const httpHeaders = this.getHTTPHeaders();
    return this.http.post<BaseModel>(URL, request,{ headers: httpHeaders })

  }

  findmenu(tableState: ITableState,id:number): Observable<TableResponseModel<T>> {
    
      const httpHeaders = this.getHTTPHeaders();
      this._errorMessage.next('');

      let page= tableState.paginator.page;
      const httpParams = new HttpParams()
      .set('pageNo', page.toString())//tableState.paginator.page.toString()
      .set('pageSize', tableState.paginator.pageSize.toString())
      .set('sortBy', tableState.sorting.column)
      .set('sortOrder', tableState.sorting.direction)
      .set('searchTerm', tableState.searchTerm);

      const url = `${environment.apiUrl}/restaurant/resturantmenu/${id}?${httpParams}` ;
      
    return this.http.post<TableResponseModel<T>>(url,null,{ headers: httpHeaders } ).pipe(
      
      catchError(err => {
        this._errorMessage.next(err);
        console.error('FIND ITEMS', err);
        return of({ items: [], total: 0 });
      })
    );
  }




  // READ (Returning filtered list of entities)
  find(tableState: ITableState): Observable<TableResponseModel<T>> {
    const url = this.API_URL ;
    this._errorMessage.next('');
    return this.http.post<TableResponseModel<T>>(url, tableState).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('FIND ITEMS', err);
        return of({ items: [], total: 0 });
      })
    );
  }

  findWithParameter(tableState: ITableState, param): Observable<TableResponseModel<T>> {
    const url = this.API_URL + '/find';
    this._errorMessage.next('');
    return this.http.post<TableResponseModel<T>>(url, tableState).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('FIND ITEMS', err);
        return of({ items: [], total: 0 });
      })
    );
  }
  getAllItem(urlpath): Observable<BaseModel> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${environment.apiUrl}/${urlpath}`;
    const httpHeaders = this.getHTTPHeaders();

    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET ALL  ITEM ', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  getItemById(id: number): Observable<BaseModel> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/${id}`;
    const httpHeaders = this.getHTTPHeaders();

    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET ITEM BY IT', id, err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  getMenuItemById(id: number,url): Observable<BaseModel> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    // const url = `${this.API_URL}/${id}`;
    const API_URL = `${environment.apiUrl}`;
    const httpHeaders = this.getHTTPHeaders();

    return this.http.get<BaseModel>(`${API_URL+url}/${id}`, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET ITEM BY IT', id, err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  getItem(): Observable<BaseModel> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}`;
    const httpHeaders = this.getHTTPHeaders();

    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET ITEM ', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // UPDATE
  update(item: any): Observable<any> {
    const url = `${this.API_URL}/${item._id}`;
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const httpHeaders = this.getHTTPHeaders();
    return this.http.put(url, item, { headers: httpHeaders })
  }


  updateResturant(_id:any,item: BaseModel): Observable<any> {
    const url = `${environment.apiUrl}/restaurant/register/${_id}`;

    this._isLoading$.next(true);
    this._errorMessage.next('');
    const httpHeaders = this.getHTTPHeaders();
    return this.http.put(url, item, { headers: httpHeaders })
  }


  updateWithParams(httpParams, urlpath): Observable<BaseModel> {
    const httpHeaders = this.getHTTPHeaders();
    this._errorMessage.next('');
    const API_URL = `${environment.apiUrl}`;
    return this.http.get<BaseModel>(API_URL + urlpath, { headers: httpHeaders, params: httpParams })
    
  }
  // UPDATE Status
  updateStatusForItems(ids: number[], status: number): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const body = { ids, status };
    const url = this.API_URL + '/updateStatus';
    return this.http.put(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('UPDATE STATUS FOR SELECTED ITEMS', ids, status, err);
        return of([]);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // DELETE
  delete(id: any): Observable<any> {    
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${environment.apiUrl}/menu/${id}`;
    const httpHeaders = this.getHTTPHeaders();
    return this.http.delete(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE ITEM', id, err);
        return of({});
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  deleteMenuCategory(id: any): Observable<any> {    
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${environment.apiUrl}/menu/menu-category/${id}`;
    const httpHeaders = this.getHTTPHeaders();
    return this.http.delete(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE ITEM', id, err);
        return of({});
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  deleteResturant(id: any): Observable<any> {    
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${environment.apiUrl}/restaurant/${id}`;
    const httpHeaders = this.getHTTPHeaders();
    return this.http.delete(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE ITEM', id, err);
        return of({});
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  deleteResturantMenu(id: any): Observable<any> {    
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${environment.apiUrl}/restaurant/menu/${id}`;
    const httpHeaders = this.getHTTPHeaders();
    return this.http.delete(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE ITEM', id, err);
        return of({});
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  addResturantMenu(id: any): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${environment.apiUrl}/restaurant/addresturantmenu/${id}`;
    const httpHeaders = this.getHTTPHeaders();

    return this.http.get(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE ITEM', id, err);
        return of({});
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // delete list of items
  deleteItems(ids: number[] = []): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = this.API_URL + '/deleteItems';
    const body = { ids };

    return this.http.put(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE SELECTED ITEMS', ids, err);
        return of([]);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  deletedItems(httpParams, path): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = this.API_URL;
    const httpHeaders = this.getHTTPHeaders();
    return this.http.delete(url, { headers: httpHeaders, params: httpParams }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE SELECTED ITEMS', err);
        return of([]);
      }),
      finalize(() => this._isLoading$.next(false))
    );




  }
  updateStatusWithIds(httpParams, path): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${environment.apiUrl}` + `${path}`;
    const httpHeaders = this.getHTTPHeaders();
    return this.http.delete(url, { headers: httpHeaders, params: httpParams }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('UPDATE SELECTED ITEMS', err);
        return of([]);
      }),
      finalize(() => this._isLoading$.next(false))
    );

  }


  public fetchMenu(id:number) {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const request1 = this.findmenu(this._tableState$.value,id)
      .pipe(
        tap((res: TableResponseModel<T>) => {
          this._items$.next(res.items);
          this.patchStateWithoutFetch({
            paginator: this._tableState$.value.paginator.recalculatePaginator(
              res.total
            ),
          });
        }),
        catchError((err) => {
          this._errorMessage.next(err);
          return of({
            items: [],
            total: 0
          });
        }),
        finalize(() => {
          this._isLoading$.next(false);
          const itemIds = this._items$.value.map((el: T) => {
            const item = (el as unknown) as BaseModel;
            return item.id;
          });
          this.patchStateWithoutFetch({
            grouping: this._tableState$.value.grouping.clearRows(itemIds),
          });
        })
      )
      .subscribe();

    this._subscriptions.unshift(request1);
  }




  public fetch() {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const request = this.find(this._tableState$.value)
      .pipe(
        tap((res: TableResponseModel<T>) => {          
          this._items$.next(res.items);
          this.patchStateWithoutFetch({
            paginator: this._tableState$.value.paginator.recalculatePaginator(
              res.total
            ),
          });
        }),
        catchError((err) => {
          this._errorMessage.next(err);
          return of({
            items: [],
            total: 0
          });
        }),
        finalize(() => {
          this._isLoading$.next(false);
          const itemIds = this._items$.value.map((el: T) => {
            const item = (el as unknown) as BaseModel;
            return item.id;
          });
          this.patchStateWithoutFetch({
            grouping: this._tableState$.value.grouping.clearRows(itemIds),
          });
        })
      )
      .subscribe();
    this._subscriptions.unshift(request);
  }

  public fetchWithParameter(param) {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const request = this.findWithParameter(this._tableState$.value, param)
      .pipe(
        tap((res: TableResponseModel<T>) => {
          this._items$.next(res.items);
          this.patchStateWithoutFetch({
            paginator: this._tableState$.value.paginator.recalculatePaginator(
              res.total
            ),
          });
        }),
        catchError((err) => {
          this._errorMessage.next(err);
          return of({
            items: [],
            total: 0
          });
        }),
        finalize(() => {
          this._isLoading$.next(false);
          const itemIds = this._items$.value.map((el: T) => {
            const item = (el as unknown) as BaseModel;
            return item.id;
          });
          this.patchStateWithoutFetch({
            grouping: this._tableState$.value.grouping.clearRows(itemIds),
          });
        })
      )
      .subscribe();
    this._subscriptions.unshift(request);
  }
  public setDefaults() {
    this.patchStateWithoutFetch({ filter: {} });
    this.patchStateWithoutFetch({ sorting: new SortState() });
    this.patchStateWithoutFetch({ grouping: new GroupingState(this.globals) });

    this.patchStateWithoutFetch({ searchTerm: '' });
    this.patchStateWithoutFetch({
      paginator: new PaginatorState()
    });
    this._isFirstLoading$.next(true);
    this._isLoading$.next(true);
    this._tableState$.next(DEFAULT_STATE);
    this._errorMessage.next('');
  }

  // Base Methods
  public patchState(patch: Partial<ITableState>) {
    this.patchStateWithoutFetch(patch);
    this.fetch();
  }

  public patchMenuState(patch: Partial<ITableState>,id:number) {
    this.patchStateWithoutFetch(patch);
    this.fetchMenu(id);
  }

  public patchState1(patch: Partial<ITableState>, data) {
    this.patchStateWithoutFetch(patch);
    this.fetchWithParameter(data);
  }

  public patchStateWithoutFetch(patch: Partial<ITableState>) {
    const newState = Object.assign(this._tableState$.value, patch);
    this._tableState$.next(newState);
  }
}
