import { Globals } from "src/app/modules/common/_services/globals.service";


export interface IGroupingState {
  selectedRowIds: Set<number>;
  itemIds: number[];
  checkAreAllRowsSelected(): boolean;
  checkAreAllRowsSelected1(): boolean;
  selectRow(id: number): IGroupingState;
  selectRow1(id: number): IGroupingState;
  // tslint:disable-next-line:variable-name
  clearRows(_itemIds: number[]): IGroupingState;
  isRowSelected(id: number): boolean;
  isRowSelected1(id: number): boolean;
  selectAllRows(): IGroupingState;
  selectAllRows1(): IGroupingState;
  getSelectedRows(): number[];
  getSelectedRows1(): number[];
  getSelectedRowsCount(): number;
  getSelectedRowsCount1(): number;
}

export class GroupingState implements IGroupingState {
  selectedRowIds: Set<number> = new Set<number>();
  itemIds = [];
  
  constructor(private global: Globals) {
    this.global.getIds()
    this.global.ids
  }
  checkAreAllRowsSelected(): boolean {
    if (this.itemIds.length === 0) {
      return false;
    }

    return this.selectedRowIds.size === this.itemIds.length;
  }

  checkAreAllRowsSelected1(): boolean {
    if (this.itemIds.length === 0) {
      return false;
    }

    return this.selectedRowIds.size === this.itemIds.length;
  }

  selectRow(id: number): GroupingState {
    // debugger
    if (this.selectedRowIds.has(id)) {
      this.selectedRowIds.delete(id);
    } else {
      this.selectedRowIds.add(id);
    }
    return this;
  }

  selectRow1(id: number): GroupingState {
    // debugger
    if (this.global.ids.has(id)) {
      this.global.ids.delete(id);
      this.selectedRowIds.delete(id);
      // const index = this.global.ids.findIndex(sid =>sid === id);
      // this.global.ids.splice(index,1)
      console.log("ids" + this.global.ids)
    } else {
      this.global.ids.add(id);
      this.selectedRowIds.add(id);
      // if(this.global.ids.length === 0){
      //   this.global.ids.push(id)
      // }
      // if(this.global.ids.length !== 0){
      //   let add = true;
      //   for (let index = 0; index < this.global.ids.length; index++) {
      //     if(this.global.ids[index] === id){
      //       add = false;
      //       break;
      //     }else{
      //       add = true;
      //     }
      //   }
      //   if(add === true){
      //     this.global.ids.push(id)
      //   }
      // }
      console.log("ids" + this.global.ids)
    }
    return this;
  }

  // tslint:disable-next-line:variable-name
  clearRows(_itemIds: number[]): GroupingState {
    this.itemIds = _itemIds;
    this.selectedRowIds = new Set<number>();
    return this;
  }

  isRowSelected(id: number): boolean {
    return this.selectedRowIds.has(id);
  }



  isRowSelected1(id: number): boolean {
    // if(this.global.ids.length !== 0){
    //   let retun = false;
    //   for (let index = 0; index < this.global.ids.length; index++) {
    //     if(this.global.ids[index] === id){
    //       retun = true;
    //       break;
    //     }else{
    //       retun = false;
    //     }
    //   }
    //   if(retun === true){
    //     return true
    //   }else{
    //     return false
    //   }
    // }
    return this.global.ids.has(id);
  }


  selectAllRows(): GroupingState {
    // debugger
    const areAllSelected = this.itemIds.length === this.selectedRowIds.size;
    if (areAllSelected) {
      this.selectedRowIds = new Set<number>();
    } else {
      this.selectedRowIds = new Set<number>();
      this.itemIds.forEach(id => this.selectedRowIds.add(id));
    }
    return this;
  }

  selectAllRows1(): GroupingState {
    // debugger
    const areAllSelected = this.itemIds.length === this.selectedRowIds.size;
    if (areAllSelected) {
      this.selectedRowIds = new Set<number>();
      this.itemIds.forEach(id => {
        if (this.global.ids.has(id)) {
          this.global.ids.delete(id);
        }
        // const index = this.global.ids.findIndex(sid =>sid === id);
        // this.global.ids.splice(index,1)
      });
    } else {
      this.selectedRowIds = new Set<number>();
      this.itemIds.forEach(id => {
        this.global.ids.add(id)
        this.selectedRowIds.add(id)
        // if(this.global.ids.length === 0){
        //   this.global.ids.push(id)
        // }
        // if(this.global.ids.length !== 0){
        //   let add = true;
        //   for (let index = 0; index < this.global.ids.length; index++) {
        //     if(this.global.ids[index] === id){
        //       add = false;
        //       break;
        //     }else{
        //       add = true;
        //     }
        //   }
        //   if(add === true){
        //     this.global.ids.push(id)
        //   }
        // }
      });
    }
    return this;
  }

  getSelectedRows(): number[] {
    return Array.from(this.selectedRowIds);
  }

  getSelectedRows1(): number[] {
    return Array.from(this.global.ids);
  }

  getSelectedRowsCount(): number {
    return this.selectedRowIds.size;
  }

  getSelectedRowsCount1(): number {
    return this.global.ids.size;
  }
}

export interface IGroupingView {
  grouping: GroupingState;
  ngOnInit(): void;
}